<template>
  <!--页面顶部-->
  <section class="topbar">
    <div class="container">
      <div class="welcome">欢迎您：<em>{{showName}}</em></div>
      <div class="topnav">
        <span>{{date}}</span>
        <a href="/#/message" :class="isMessage ? 'active' : ''"><i class="icon-message"></i>信息中心</a>
        <a href="javascript:;" @click="logOut"><i class="icon-logout"></i>退出平台</a>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "topHead",
    // isMessage
    props:{
      isMessage:{//标题
        type:Boolean,
        default:false
      },
      name:{//标题
        type:String,
        default:''
      },
    },
    data() {
      return {
        date: '',
        showName:'',
      }
    },
    created() {
      this.getToday();
      if(!sessionStorage.getItem('name')){
        setTimeout(() => {
          this.showName = sessionStorage.getItem('name');
        },500)
      }else{
        this.showName = sessionStorage.getItem('name');
      }

      // this.getBusinessInfo();
    },
    methods: {
      getBusinessInfo(){
        this.$api.getBusinessInfo().then(res => {
          this.basicInfo = res.data;

        })
      },
      logOut(){
        this.$api.logout({token:this.$global.token}).then(res => {
          this.$global.token = '';
          sessionStorage.removeItem('token');
          this.$message.success('操作成功');
          this.$router.push('/login')
        })
      },
      // 获取今日中文日期
      getToday() {
        var myDate = new Date();
        var year = myDate.getFullYear();
        var month = myDate.getMonth() + 1;
        var day = myDate.getDate();
        var weekList = ['星期天','星期一','星期二','星期三','星期四','星期五','星期六'];
        var week = weekList[myDate.getDay()];
        this.date = year + '年' + month + '月' + day + '日' + ' ' + week
      }
    }
  }
</script>

<style scoped>

</style>
