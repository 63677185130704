import axios from 'axios';
const host = require('./host');
import { ElMessage } from 'element-plus'
import router from '../router/index'


const service = axios.create({
  // process.env.NODE_ENV === 'development' 来判断是否开发环境
  baseURL: process.env.NODE_ENV === 'development' ? host.host : host.host,
  timeout: 60000000
});

service.interceptors.request.use( config => {
  const token = host.token || sessionStorage.getItem('token');
  if(token){
    config.headers = {'token' : token};
  }
  config.headers['appId'] = host.appId;
  if (config.ContentType) {
    config.headers['Content-Type'] = config.ContentType;
    delete config.ContentType
  }
  return config;
}, error => {
  return Promise.reject();
});

service.interceptors.response.use(response => {
  if(response.status === 200){
    if(response.data.status == 1){
      return response.data;
    }else if(response.data.code == '20009'){
      ElMessage.error('会话失效，即将请重新登录');
      setTimeout(() => {
        sessionStorage.removeItem('token');
        host.token = '';
        router.push('/login')
      },1500)
    }else{
      console.log(response)
      response.data.message ? ElMessage.error(response.data.message) : '';
      return  Promise.reject(response);
    }
  }else{
    return Promise.reject(response);
  }
}, error => {

  ElMessage.error('无请求参数');
  return Promise.reject(error);
});

export default service;
