<template>
  <section class="nav">
    <div class="container">
      <a href="/" class="logo" title="分销商开放平台"></a>
      <nav>
        <div class="navbox">
          <div class="subject">
            <a href="/" title="首页">
              <i class="icon-index"></i>
              <p>首页</p>
            </a>
          </div>
        </div>
        <div class="navbox">
          <div class="subject">
            <a href="/#/ticket" title="票务预订">
              <i class="icon-ticket"></i>
              <p>票务预订</p>
            </a>
          </div>
        </div>
        <div class="navbox">
          <div class="subject">
            <a href="/#/ticketQP" title="期票预订">
              <i class="icon-ticketsp"></i>
              <p>期票预订</p>
            </a>
          </div>
        </div>
        <div class="navbox">
          <div class="subject">
            <a href="/#/trip" title="订单中心">
              <i class="icon-order"></i>
              <p>订单中心</p>
            </a>
          </div>
          <ul>
            <a href="/#/trip" title="行程单管理">行程单管理</a>
            <a href="/#/order" title="订单管理">订单管理</a>
            <a href="/#/reservation" title="预约单管理">预约单管理</a>
          </ul>
        </div>
        <div class="navbox">
          <div class="subject">
            <a href="/#/report" title="报表中心">
              <i class="icon-report"></i>
              <p>报表中心</p>
            </a>
          </div>
          <ul>
            <a href="/#/report" title="消费明细报表">消费明细报表</a>
            <a href="/#/paymentDetail" title="支付明细报表">支付明细报表</a>
          </ul>
        </div>
        <div class="navbox">
          <div class="subject">
            <a href="/#/finance" title="财务管理">
              <i class="icon-finance"></i>
              <p>财务管理</p>
            </a>
          </div>
          <ul>
            <a href="/#/finance" title="账户余额">账户余额</a>
            <a href="/#/quota" title="授信额度">授信额度</a>
          </ul>
        </div>
        <div class="navbox">
          <div class="subject">
            <a href="/#/userCenter" title="用户中心">
              <i class="icon-userCenter"></i>
              <p>用户中心</p>
            </a>
          </div>
          <ul>
            <a href="/#/userCenter" title="分销商信息">分销商信息</a>
            <!--<a href="/#/bill" title="我的账单">我的账单</a>-->
            <a href="/#/changePassword" title="修改密码">修改密码</a>
            <a href="/#/booker" title="预订人管理">预订人管理</a>
            <!--<a href="/#/subordinate" title="子分销商管理">子分销商管理</a>-->
          </ul>
        </div>
      </nav>
    </div>
  </section>
</template>

<script>
  export default {
    name: "logoNav",
    data() {
      return {}
    },
    methods: {}
  }
</script>

<style scoped>
  .nav{
    box-shadow: 0px 4px 10px 0px rgba(208, 227, 235, 0.35);
  }
</style>
