<template>

  <section class="footer">
    <ul>
      <li>Copyright©2019 乐清慢方适文化旅游有限公司版权所有</li>
      <li>技术支持：四川领创数智科技有限公司</li>
    </ul>
  </section>
</template>

<script>
  export default {
    name: "footer",
    data() {
      return {}
    },
    methods: {}
  }
</script>

<style scoped>
  .footer{
    border-top: 6px solid #1cd0bd;
  }
  .footer ul{
    background: #1cd0bd;
    color: #fff;
    margin-top: 5px;
    font-size: 14px;
    line-height: 30px;
    padding: 20px 0;
  }
  .footer ul li{
    list-style-type: none;
  }
  .footer ul span{
    margin: 0 20px;
  }
</style>
