import request from '../util/request';

function setUrlParam(data) {
  let _str = '';
  for (let i in data) {
    if (!isNull(data[i])) {
      _str += i + '=' + encodeURIComponent(data[i]) + '&';
    }
  }
  return '?' + _str.substring(0, _str.length - 1);
}

function isNull(e) {
  let _ = String(e);
  return (_ === 'null' || _ === 'NaN' || _ === 'undefined' || _.length === 0);
}


const api = {
  loginPost(data) { //登录
    return request({
      // url: '/auth/loginPost',
      url: '/auth/fxsLoginPost',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  upload(data) {//上传
    return request({
      url: '/uploadFile/upload',
      ContentType: 'multipart/form-data',
      method: 'POST',
      data:data,
    })
  },
  registBusinessInfo(data) {//注册
    return request({
      url: '/dpBusinessInfo/registBusinessInfo',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  logout(data) {//退出登录
    return request({
      url: '/auth/logout'+ setUrlParam(data),
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  getEnumsByCode(data) {//获取枚举
    return request({
      url: '/system/getEnumsByCode/'+data,
      ContentType: 'application/json;charset=UTF-8',
      method: 'GET',
    })
  },
  queryDisGroupBizType(data) {//获取商品类型
    return request({
      url: '/disticket/queryDisGroupBizType',
      ContentType: 'application/json;charset=UTF-8',
      method: 'GET',
    })
  },
  changePassword(data) {//修改密码
    return request({
      url: '/auth/changePassword',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  queryDisGroupList(data) {//获取产品列表
    return request({
      url: '/disticket/queryDisGroupList',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  searchList(data) {//预订人列表
    return request({
      url: '/dpReservationPerson/searchList',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  addYD(data) {//add预订人
    return request({
      url: '/dpReservationPerson/add',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  deleteBatch(data) {//删除预订人
    return request({
      url: '/dpReservationPerson/deleteBatch',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  modify(data) {//修改预订人
    return request({
      url: '/dpReservationPerson/modify',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  queryDisGroupAndCommditys(data) {//根据产品ID返回产品信息及商品明细(及价格)
    return request({
      url: '/disticket/queryDisGroupAndCommditys',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  getBusinessInfo(data) {//分销商基本信息
    return request({
      url: '/dpBusinessInfo/getBusinessInfo',
      ContentType: 'application/json;charset=UTF-8',
      method: 'GET',
    })
  },
  queryWinCredentials(data) {//获取证件类型
    return request({
      url: '/winticket/queryWinCredentials',
      ContentType: 'application/json;charset=UTF-8',
      method: 'GET',
    })
  },
  queryWinVisitorType(data) {//获取游客类型
    return request({
      url: '/winticket/queryWinVisitorType',
      ContentType: 'application/json;charset=UTF-8',
      method: 'GET',
    })
  },
  queryPage(data) {//查询通知公告
    return request({
      url: '/news/queryPage',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  getByIdNotice(data) {//查询通知公告详情
    return request({
      url: '/news/getById/'+data,
      ContentType: 'application/json;charset=UTF-8',
      method: 'GET',
    })
  },
  getBusinessWallet(data) {//查询钱包
    return request({
      url: '/dpBusinessInfo/getBusinessWallet',
      ContentType: 'application/json;charset=UTF-8',
      method: 'GET',
    })
  },
  queryPageFi(data) {//查询账户余额列表
    return request({
      url: '/capitalChangeLog/queryPage',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  getWithdrawWerviceRatio(data) {//提现手续比例
    return request({
      url: '/financeAdvanceCharge/getWithdrawWerviceRatio',
      ContentType: 'application/json;charset=UTF-8',
      method: 'GET',
    })
  },
  apply(data) {//提现
    return request({
      url: '/financeAdvanceCharge/apply',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  createTicket(data) {//下单
    return request({
      url: '/ticketItinerary/create',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  createOrder(data) {//下单
    return request({
      url: '/order/create',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  createOrderQp(data) {//下单
    return request({
      url: '/order/createNonRealName',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  modityTicket(data) {//下单修改
    return request({
      url: '/ticketItinerary/modity',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  itineraryApprove(data) {//行程单提交审核
    return request({
      url: '/ticketItinerary/itineraryApprove'+setUrlParam(data),
      ContentType: 'application/json;charset=UTF-8',
      method: 'GET',
      data:data,
    })
  },
  queryItineraryGroup(data) {//查询行程单
    return request({
      url: '/ticketItinerary/queryItineraryGroup'+setUrlParam(data),
      ContentType: 'application/json;charset=UTF-8',
      method: 'GET',
      // data:data,
    })
  },
  queryOrderGroup(data) {//查询支付那儿的订单行程单
    return request({
      url: '/order/queryOrderGroup'+setUrlParam(data),
      ContentType: 'application/json;charset=UTF-8',
      method: 'GET',
      // data:data,
    })
  },
  queryDisPayType(data) {//查询支付方式
    return request({
      url: '/disticket/queryDisPayType',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
    })
  },
  getSysTime() {//查询系统时间
    return request({
      url: '/base/getSysTime',
      ContentType: 'application/json;charset=UTF-8',
      method: 'GET',
    })
  },
  itineraryCreateOrder(data) {//行程单审核通过生成订单
    return request({
      url: '/ticketItinerary/itineraryCreateOrder'+setUrlParam(data),
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  queryItineraryPage(data) {//查询行程单liebiao
    return request({
      url: '/ticketItinerary/queryItineraryPage',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  itineraryCancel(data) {//取消行程单
    return request({
      url: '/ticketItinerary/itineraryCancel'+setUrlParam(data),
      ContentType: 'application/json;charset=UTF-8',
      method: 'GET',
      data:data,
    })
  },
  createPayInfo(data) {//支付
    return request({
      url: '/payInfo/createPayInfo',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  queryWinOrderStatus(data) {//支付状态
    return request({
      url: '/winticket/queryWinOrderStatus'+setUrlParam(data),
      ContentType: 'application/json;charset=UTF-8',
      method: 'GET',
      data:data,
    })
  },
  queryOrderList(data) {//订单列表
    return request({
      url: '/order/queryOrderList',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  disRefundApply(data) {//订单退款
    return request({
      url: '/disticket/disRefundApply',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  queryOrderRefundList(data) {//订单退款Liebiao
    return request({
      url: '/disticket/queryOrderRefundList'+setUrlParam(data),
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  queryOrderRefundDetail(data) {//订单退款详情
    return request({
      url: '/disticket/queryOrderRefundDetail'+setUrlParam(data),
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  createPayInfoFinance(data) {//分销商充值
    return request({
      url: '/finance_pay/createPayInfo',
      ContentType: 'application/json;charset=UTF-8',
      method: 'POST',
      data:data,
    })
  },
  queryPayInfo(data) {//查询支付状态
    return request({
      url: '/finance_pay/queryPayInfo'+setUrlParam(data),
      ContentType: 'application/json;charset=UTF-8',
      method: 'GET',
      data:data,
    })
  },
};

export default api;
