import { createRouter, createWebHashHistory } from 'vue-router'

const host = require('../util/host');
const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { title: '铁定溜溜乐园 - 分销商开放平台' },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: '登录 - 分销商开放平台' },
    component: () => import('../views/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: { title: '注册 - 分销商开放平台' },
    component: () => import('../views/register.vue')
  },
  {
    path: '/findPassword',
    name: 'findPassword',
    meta: { title: '找回密码 - 分销商开放平台' },
    component: () => import('../views/findPassword.vue')
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    meta: { title: '重置密码 - 分销商开放平台' },
    component: () => import('../views/resetPassword.vue')
  },
  {
    path: '/inAudit',
    name: 'inAudit',
    meta: { title: '审核中 - 分销商开放平台' },
    component: () => import('../views/inAudit.vue')
  },
  {
    path: '/message',
    name: 'message',
    meta: { title: '信息中心 - 分销商开放平台' },
    component: () => import('../views/message.vue')
  },
  {
    path: '/messageDetails',
    name: 'messageDetails',
    meta: { title: '信息中心 - 分销商开放平台' },
    component: () => import('../views/messageDetails.vue')
  },
  {
    path: '/ticket',
    name: 'ticket',
    meta: { title: '票务预定 - 分销商开放平台' },
    component: () => import('../views/ticket.vue')
  },
  {
    path: '/ticketQP',
    name: 'ticketQP',
    meta: { title: '期票预定 - 分销商开放平台' },
    component: () => import('../views/ticketQP.vue')
  },
  {
    path: '/order',
    name: 'order',
    meta: { title: '订单中心 - 分销商开放平台' },
    component: () => import('../views/order.vue')
  },
  {
    path: '/trip',
    name: 'trip',
    meta: { title: '行程单管理 - 分销商开放平台' },
    component: () => import('../views/trip.vue')
  },
  {
    path: '/tripDetail',
    name: 'tripDetail',
    meta: { title: '行程单详情 - 分销商开放平台' },
    component: () => import('../views/tripDetail.vue')
  },
  {
    path: '/report',
    name: 'report',
    meta: { title: '报表中心 - 分销商开放平台' },
    component: () => import('../views/report.vue')
  },
  {
    path: '/paymentDetail',
    name: 'paymentDetail',
    meta: { title: '支付明细报表 - 分销商开放平台' },
    component: () => import('../views/paymentDetail.vue')
  },
  {
    path: '/finance',
    name: 'finance',
    meta: { title: '财务管理 - 分销商开放平台' },
    component: () => import('../views/finance.vue')
  },
  {
    path: '/quota',
    name: 'quota',
    meta: { title: '授信额度 - 分销商开放平台' },
    component: () => import('../views/quota.vue')
  },
  {
    path: '/userCenter',
    name: 'userCenter',
    meta: { title: '用户中心 - 分销商开放平台' },
    component: () => import('../views/userCenter.vue')
  },
  {
    path: '/bill',
    name: 'bill',
    meta: { title: '我的账单 - 分销商开放平台' },
    component: () => import('../views/bill.vue')
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    meta: { title: '修改密码 - 分销商开放平台' },
    component: () => import('../views/changePassword.vue')
  },
  {
    path: '/booker',
    name: 'booker',
    meta: { title: '预订人管理 - 分销商开放平台' },
    component: () => import('../views/booker.vue')
  },
  {
    path: '/subordinate',
    name: 'subordinate',
    meta: { title: '子分销商管理 - 分销商开放平台' },
    component: () => import('../views/subordinate.vue')
  },
  {
    path: '/orderDetails',
    name: 'orderDetails',
    meta: { title: '订单详情 - 分销商开放平台' },
    component: () => import('../views/orderDetails.vue')
  },
  {
    path: '/cashing',
    name: 'cashing',
    meta: { title: '财务管理 - 余额提现申请' },
    component: () => import('../views/cashing.vue')
  },
  {
    path: '/orderRefund',
    name: 'orderRefund',
    meta: { title: '订单中心 - 订单退款' },
    component: () => import('../views/orderRefund.vue')
  },
  {
    path: '/refundDetail',
    name: 'refundDetail',
    meta: { title: '订单中心 - 退款详情' },
    component: () => import('../views/refundDetail.vue')
  },
  {
    path: '/fillIn',
    name: 'fillIn',
    meta: { title: '票务中心 - 填写购票人信息' },
    component: () => import('../views/fillInNew.vue')
  },
  {
    path: '/fillInQP',
    name: 'fillInQP',
    meta: { title: '票务中心 - 填写购票人信息' },
    component: () => import('../views/fillInQPNew.vue')
  },
  {
    path: '/submitOrder',
    name: 'submitOrder',
    meta: { title: '票务中心 - 提交行程单' },
    component: () => import('../views/submitOrder.vue')
  },
  {
    path: '/pay',
    name: 'pay',
    meta: { title: '票务中心 - 确认支付' },
    component: () => import('../views/pay.vue')
  },
  {
    path: '/payQp',
    name: 'payQp',
    meta: { title: '票务中心 - 确认支付' },
    component: () => import('../views/payQp.vue')
  },
  {
    path: '/paySuccess',
    name: 'paySuccess',
    meta: { title: '票务中心 - 支付成功' },
    component: () => import('../views/paySuccess.vue')
  },
  {
    path: '/paySuccessQp',
    name: 'paySuccessQp',
    meta: { title: '票务中心 - 支付成功' },
    component: () => import('../views/paySuccessQp.vue')
  },
  {
    path: '/reservation',
    name: 'reservation',
    meta: { title: '订单中心 - 预约单管理' },
    component: () => import('../views/reservation.vue')
  },
  {
    path: '/reservationDetail',
    name: 'reservationDetail',
    meta: { title: '订单中心 - 预约单详情' },
    component: () => import('../views/reservationDetail.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => { //beforeEach是router的钩子函数，在进入路由前执行

  if (to.meta.title) { //判断是否有标题

    document.title = to.meta.title

  }
  let token = sessionStorage.getItem('token');
  if(!token && to.path != '/login' && to.path != '/register' && to.path != '/inAudit'){
    router.push('/login')
  }else{
    if(to.path != '/submitOrder' && to.path != '/fillIn' && to.path != '/fillInQP' && to.path != '/payQp'){
      sessionStorage.removeItem('goodsInfo');
      sessionStorage.removeItem('saveInfo');
      sessionStorage.removeItem('timeCheck');
      sessionStorage.removeItem('time');
    }

    next()//执行进入路由，如果不写就不会进入目标页
  }


})
export default router
