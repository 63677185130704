import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import './assets/css/element-variables.scss'
import './assets/css/common.css'
import './assets/css/common_h.scss'
import './assets/css/tdll.scss'
import md5 from 'js-md5';
import api from './api/index'
import global from './util/host'

import TopHead from './components/topHead.vue';//顶部黑条
import logoNav from './components/logoNav.vue';//黑条下面的导航栏
import footerUl from './components/footerUl.vue';//底部



const app = createApp(App)
  .use(store)
  .use(ElementPlus,{
    locale: zhCn,
  })
  .use(router);
app.component('TopHead',TopHead);
app.component('logoNav',logoNav);
app.component('footerUl',footerUl);

app.config.globalProperties.$api = api; // 全局定义api
app.config.globalProperties.$md5 = md5; // 全局定义$md5
app.config.globalProperties.$global = global; // 全局定义global
app.mount('#app')
