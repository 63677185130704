<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<style lang="scss">
  .ch_btn{
    div{
      width: 281px;
      height: 84px;
      background: var(--el-color-primary);
      border-radius: 14px;
      border: 1px solid var(--el-button-border-color);
      line-height: 82px;
      box-sizing: border-box;
      font-size: 28px;
      font-weight: bold;
      text-align: center;
      color: var(--el-button-font-color);
      cursor: pointer;
      &.ac{
        background: #FFFFFF;
        color: var(--el-color-primary);
        &:hover{
          background: var(--el-color-primary-light-9);
        }
      }
      transition: .2s linear;
      &:hover{
        background: var(--el-button-hover-color);
      }
    }
  }
  .el-table{
    border: 1px solid #F4F4F4 !important;
    text-align: center;
  }
  .uc_title{
    .cut{
      height: 1px;
      background: #E0E0E0;
      margin: 0 20px 0 14px;
    }
    img.close{
      width: 22px;
      height: 22px;
      &:hover{
        transform: rotate(180deg);
        transition: .3s linear;
      }
    }
    .uc_green{
      width: 6px;
      height: 28px;
      background: var(--el-color-primary);
      margin-right: 13px;
    }
    font-size: 28px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 30px;
  }
  .uc_con{
    background-color: white;
    margin: 28px;
    padding: 30px 25px;
    border-radius: 14px;
    text-align: left;
    box-sizing: border-box;
  }


  .add_btn{
    display: inline-block;
    height: 34px;
    background: #1CD0BD;
    border-radius: 6px;
    margin-left: 76px;
    line-height: 34px;
    padding: 0 15px;
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
    &:hover{
      background: rgba(28, 208, 189, 0.8);
    }
  }
  .right_btn{
    img{
      height: 22px;
      margin-right: 5px;
    }
    &.big{
      width: 120px;
    }
    width: 100px;
    justify-content: center;
    height: 42px;
    border: 1px solid #333333;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    box-sizing: border-box;
    transition: .1s linear;
    &:hover{
      img{
        display: none;
      }
      font-size: 18px;
      border: 1px solid var(--el-color-primary);
      color: var(--el-color-primary);
      background: var(--el-color-primary-light-9);

    }
  }

  .tdr_title{
    div{
      height: 8px;
      background: #F5BB4A;
      width: 100%;
      top: 16px;
    }
    span{
      position: relative;
      display: inline-block;
      background-color: white;
      padding: 0 15px;
      z-index: 1;
    }
    font-size: 28px;
    font-weight: bold;
    color: #333333;
  }
  .td_rp .ch_btn{
    div{
      width: 100%;
    }
    margin-top: 50px;
  }
  .td_rp{
    right: 25px;
    top: calc(110PX + 30px);
    width: 385px;
    background: #FFFFFF;
    height: calc(100vh - 208px);
    border-radius: 14px;
    padding: 35px 24px;
    box-sizing: border-box;
  }
  .td_r{
    margin-left: 40px;
    width: 385px;
  }
  .td .cp_btn{
    width: 228px!important;
    height: 79px!important;
    line-height: 77px!important;
    &.ac{
      margin-left: 70px!important;
    }
    margin-bottom: 70px;
  }
  .t_num{
    color: var(--el-color-primary);
    font-style: italic;
    display: inline-block;
    padding-bottom: 7px;
    border-bottom: 1px solid var(--el-color-primary);
  }
</style>
